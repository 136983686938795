




































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import { CatastrophicInjuryLookupModel, CatastrophicInjuryModel } from '@/core/webapi';
import { AutoSaveService, ApiService } from '@/core/services';
import { QcFormElementWithAlertCpt } from '../components';
import {
  BaseFormComponent,
  InputFieldCpt,
  InputTextareaCpt,
  InputDateCpt,
  InputSelectCpt,
  InputRadioCpt,
  InputSwitchCpt,
  InputYesNoNotKnownCpt,
  ValidationHintCpt,
  SpinnerCpt,
} from '@/core/components';

@Component({
  components: {
    QcFormElementWithAlertCpt,
    InputFieldCpt,
    InputTextareaCpt,
    InputDateCpt,
    InputSelectCpt,
    InputRadioCpt,
    InputSwitchCpt,
    InputYesNoNotKnownCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class CatastrophicInjuryFormView extends BaseFormComponent {
  lookups = {} as CatastrophicInjuryLookupModel;
  model = new CatastrophicInjuryModel({ modelState: {} } as any);
  comparisonModel: CatastrophicInjuryModel;
  pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
  };
  autoSaveTimeoutId?: number;
  loading = true;

  // stingerInjuryYearsNotKnown = false;

  get injuryId() {
    return this.getUrlParamAsNumber('injuryId');
  }

  get autoSaveId() {
    return this.getUrlParamAsNumber('autoSaveId');
  }

  get isRemovalOtherSelected() {
    return !!this.model.removalEquipmentIds?.includes(7);
  }

  get isPlayingEquipmentOtherSelected() {
    return !!this.model.playingEquipmentIds?.includes(4);
  }

  // TODO: Add get selectedStudy once catastrophic injures get associated with studies

  async created() {
    try {
      await this.initializeModel();
      await this.initializeLookupData();
      this.initializeAutoSave();
    } finally {
      this.loading = false;
    }
  }

  // onStingerInjuryYearsNotKnown() {
  //   this.model.stingerInjuryYears = null as any;
  // }

  destroyed() {
    this.clearAutoSaveTimeout();
  }

  async onSubmitForm() {
    this.clearAutoSaveTimeout();

    try {
      this.injuryId
        ? await ApiService.catastrophicInjuries()
            .updateCatastrophicInjury(this.model)
            .handleValidationErrors(this.model)
        : await ApiService.catastrophicInjuries()
            .createCatastrophicInjury(this.model)
            .handleValidationErrors(this.model);
    } catch (e) {
      // Typically we can allow the failed request (422) to stop the execution flow of onSubmitForm(),
      // but it breaks the autoSave feature timeout, so we need to handle it explicitly
      return;
    }

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('lbl.injuryReportSaved') as string,
      type: 'success',
    });

    if (this.model.autoSaveId) {
      await ApiService.autoSave().deleteAutoSave(this.model.autoSaveId);
      this.clearAutoSaveTimeout();
    }

    this.$router.push('/catastrophic-injuries');
  }

  async discard() {
    const message = this.$t('confirm.discardReportChanges') as string;
    if (!confirm(message)) {
      return;
    }

    this.clearAutoSaveTimeout();
    if (!this.model.autoSaveId) {
      return;
    }

    if (this.model.autoSaveId) {
      await ApiService.autoSave().deleteAutoSave(this.model.autoSaveId);
    }

    this.$router.push('/catastrophic-injuries');
  }

  private async initializeModel() {
    if (this.injuryId) {
      const model = await this.getModel(this.injuryId);
      _.extend(this.model, model);
    } else if (this.autoSaveId) {
      const model = await AutoSaveService.getCatastrophicInjuryAutoSave(this.autoSaveId);
      _.extend(this.model, model);
    } else {
      this.model.organisationId = this.currentOrganisationId;
    }

    this.updateComparisonModel();
  }

  private async getModel(injuryId: number) {
    if (this.isReporter) {
      const autoSave = await AutoSaveService.findCatastrophicInjuryAutoSave(injuryId);
      if (autoSave) {
        return autoSave;
      }
    }

    return (await ApiService.catastrophicInjuries()
      .getCatastrophicInjury(injuryId)
      .getDataAndDefaultValidationProps()) as CatastrophicInjuryModel;
  }

  private async initializeLookupData() {
    this.lookups = (await ApiService.catastrophicInjuries().getCatastrophicInjuryFormLookupData()).data;
    this.updateComparisonModel();
  }

  private updateComparisonModel() {
    this.comparisonModel = JSON.parse(JSON.stringify(this.model)); // Make a copy
  }

  private initializeAutoSave() {
    if (!this.isReporter) {
      // Auto-saving is only for reporters really
      return;
    }

    this.clearAutoSaveTimeout();

    this.autoSaveTimeoutId = setTimeout(async () => {
      if (!this.isAuthenticated) {
        return;
      }

      if (this.autoSaveTimeoutId) {
        const autoSave = await AutoSaveService.autoSaveCatastrophicInjury(this.model, this.comparisonModel);
        if (!!autoSave) {
          this.model.autoSaveId = autoSave.id;
          this.updateComparisonModel();
        }
        this.initializeAutoSave();
      }
    }, 5000);
  }

  private clearAutoSaveTimeout() {
    if (this.autoSaveTimeoutId) {
      clearTimeout(this.autoSaveTimeoutId);
      this.autoSaveTimeoutId = undefined;
    }
  }
}
