



































































































































































































































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import { CommunityInjuryLookupModel, CommunityInjuryModel } from '@/core/webapi';
import { ReportLookupService, AutoSaveService, ApiService } from '@/core/services';
import { QcFormElementWithAlertCpt } from '../components';
import {
  BaseFormComponent,
  InputFieldCpt,
  InputTextareaCpt,
  InputDateCpt,
  InputSelectCpt,
  InputRadioCpt,
  InputSwitchCpt,
  InputYesNoNotKnownCpt,
  ValidationHintCpt,
  SpinnerCpt,
} from '@/core/components';
import { InjuryTimes, QcStatus, StudyTypes } from '@/core/constants';
import { SimpleLookupModel } from '@/core/models';

@Component({
  components: {
    QcFormElementWithAlertCpt,
    InputFieldCpt,
    InputTextareaCpt,
    InputDateCpt,
    InputSelectCpt,
    InputRadioCpt,
    InputSwitchCpt,
    InputYesNoNotKnownCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class CommunityInjuryFormView extends BaseFormComponent {
  lookups = {} as CommunityInjuryLookupModel;
  model = new CommunityInjuryModel({ modelState: {} } as any);
  comparisonModel: CommunityInjuryModel;
  pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
  };
  autoSaveTimeoutId?: number;
  showDialogForReporter = false;
  showDialogForAnalyst = false;
  loading = true;
  isQcComplete = false;

  get injuryId() {
    return this.getUrlParamAsNumber('injuryId');
  }

  get autoSaveId() {
    return this.getUrlParamAsNumber('autoSaveId');
  }

  get organisations() {
    return ReportLookupService.filterOrgs(this.lookups, StudyTypes.Community);
  }

  get studies() {
    return ReportLookupService.filterOrgStudies(this.lookups, this.model.organisationId, StudyTypes.Community);
  }

  get selectedStudy() {
    return this.studies ? this.studies.find(p => p.id === this.model.studyId) : null;
  }

  get sex() {
    if (!this.selectedStudy) {
      return [];
    }

    return this.lookups.sex?.filter((p: SimpleLookupModel) => p.id === this.selectedStudy!.sexId) ?? [];
  }

  get playingPositionsAtTimeOfInjury() {
    if (!this.model.gameFormatId) {
      return [];
    }

    return (
      this.lookups.playingPositions?.filter(
        p => this.model.gameFormatId && p.gameFormatIds?.includes(this.model.gameFormatId),
      ) ?? []
    );
  }

  get injuryTypes() {
    return this.model.injuryLocationId
      ? this.lookups.injuryLocations?.find(p => p.id === this.model.injuryLocationId)?.injuryTypes ?? []
      : [];
  }

  get showGameFormatSelect() {
    return (
      this.model.injuryTimeId === InjuryTimes.MatchFirstHalf || this.model.injuryTimeId === InjuryTimes.MatchSecondHalf
    );
  }

  async created() {
    try {
      await this.initializeModel();
      await this.initializeLookupData();
      this.initializeAutoSave();
    } finally {
      this.loading = false;
    }
  }

  destroyed() {
    this.clearAutoSaveTimeout();
  }

  onOrganisationChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.model.studyId;
  }

  onInjuryTimeChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.model.positionAtTimeOfInjuryId;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.model.gameFormatId;
  }

  onGameFormatChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.model.positionAtTimeOfInjuryId;
  }

  onInjuryLocationChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.model.injuryTypeId;
  }

  async onSubmitForm() {
    if (this.isReporter) {
      this.showDialogForReporter = true;
    } else if (this.isAnalyst) {
      if (this.isQcComplete) {
        this.model.qcStatusId = QcStatus.Complete;
        await this.completeAnalystSubmission();
      } else {
        this.showDialogForAnalyst = true;
      }
      this.showDialogForAnalyst = true;
    }
  }

  onDialogClose() {
    this.showDialogForReporter = false;
    this.showDialogForAnalyst = false;
  }

  async onReporterDialogButton(isReadyForQc: boolean) {
    this.model.qcStatusId = isReadyForQc ? QcStatus.AwaitingAnalyst : QcStatus.AwaitingReporter;
    this.showDialogForReporter = false;

    this.clearAutoSaveTimeout();

    try {
      this.injuryId
        ? await ApiService.communityInjuries().updateCommunityInjury(this.model).handleValidationErrors(this.model)
        : await ApiService.communityInjuries().createCommunityInjury(this.model).handleValidationErrors(this.model);
    } catch (e) {
      // Typically we can allow the failed request (422) to stop the execution flow of onSubmitForm(),
      // but it breaks the autoSave feature timeout, so we need to handle it explicitly
      return;
    }

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('lbl.injuryReportSaved') as string,
      type: 'success',
    });

    if (this.model.autoSaveId) {
      await ApiService.autoSave().deleteAutoSave(this.model.autoSaveId);
      this.clearAutoSaveTimeout();
    }

    this.$router.push('/c/injuries');
  }

  async onAnalystDialogButton(requireReporterInfo: boolean) {
    this.model.qcStatusId = requireReporterInfo ? QcStatus.AwaitingReporter : QcStatus.AwaitingAnalyst;
    this.showDialogForAnalyst = false;
    await this.completeAnalystSubmission();
  }

  async completeAnalystSubmission() {
    try {
      await ApiService.communityInjuries().saveQualityControl(this.model);
    } catch (e) {
      // Typically we can allow the failed request (422) to stop the execution flow of onSubmitForm(),
      // but it breaks the autoSave feature timeout, so we need to handle it explicitly
      return;
    }

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('msg.injuryReviewSaved') as string,
      type: 'success',
    });

    this.$router.push('/c/injuries');
  }

  async discard() {
    const message = this.$t('confirm.discardReportChanges') as string;
    if (!confirm(message)) {
      return;
    }

    this.clearAutoSaveTimeout();
    if (!this.model.autoSaveId) {
      return;
    }

    if (this.model.autoSaveId) {
      await ApiService.autoSave().deleteAutoSave(this.model.autoSaveId);
    }

    this.$router.push('/c/injuries');
  }

  private async initializeModel() {
    if (this.injuryId) {
      const model = await this.getModel(this.injuryId);
      this.isQcComplete = model.qcStatusId === QcStatus.Complete;
      _.extend(this.model, model);
    } else if (this.autoSaveId) {
      const model = await AutoSaveService.getCommunityInjuryAutoSave(this.autoSaveId);
      _.extend(this.model, model);
    }

    this.updateComparisonModel();
  }

  private async getModel(injuryId: number) {
    if (this.isReporter) {
      const autoSave = await AutoSaveService.findCommunityInjuryAutoSave(injuryId);
      if (autoSave) {
        return autoSave;
      }
    }

    return (await ApiService.communityInjuries()
      .getCommunityInjury(injuryId)
      .getDataAndDefaultValidationProps()) as CommunityInjuryModel;
  }

  private async initializeLookupData() {
    this.lookups = (await ApiService.communityInjuries().getCommunityInjuryFormLookupData()).data;
    ReportLookupService.resolveOrgStudyFilterIds(this.lookups, this.model, false, StudyTypes.Community);
    this.updateComparisonModel();
  }

  private updateComparisonModel() {
    this.comparisonModel = JSON.parse(JSON.stringify(this.model)); // Make a copy
  }

  private initializeAutoSave() {
    if (!this.isReporter) {
      // Auto-saving is only for reporters really
      return;
    }

    this.clearAutoSaveTimeout();

    this.autoSaveTimeoutId = setTimeout(async () => {
      if (!this.isAuthenticated) {
        return;
      }

      if (this.autoSaveTimeoutId) {
        const autoSave = await AutoSaveService.autoSaveCommunityInjury(this.model, this.comparisonModel);
        if (!!autoSave) {
          this.model.autoSaveId = autoSave.id;
          this.updateComparisonModel();
        }
        this.initializeAutoSave();
      }
    }, 5000);
  }

  private clearAutoSaveTimeout() {
    if (this.autoSaveTimeoutId) {
      clearTimeout(this.autoSaveTimeoutId);
      this.autoSaveTimeoutId = undefined;
    }
  }
}
