
























































































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { CommunityInjuryLookupModel, CommunityInjuryModel } from '@/core/webapi';
import { ApiService, ReportLookupService } from '@/core/services';
import {
  LookupValueCpt,
  DetailHeaderCpt,
  DetailsTableRowCpt,
  DetailsLookupTableRowCpt,
  DetailsDateTableRowCpt,
  DetailsYesNoUnknownCpt,
  SpinnerCpt,
} from '@/core/components';
import { DetailsMultiSelectLookupRowCpt } from '../components';
import { StudyTypes } from '@/core/constants';

@Component({
  components: {
    LookupValueCpt,
    DetailHeaderCpt,
    DetailsTableRowCpt,
    DetailsLookupTableRowCpt,
    DetailsDateTableRowCpt,
    DetailsYesNoUnknownCpt,
    DetailsMultiSelectLookupRowCpt,
    SpinnerCpt,
  },
})
export default class CommunityInjuryDetailsView extends AppVue {
  model = {} as CommunityInjuryModel;
  lookups = {} as CommunityInjuryLookupModel;
  loading = true;

  get injuryId() {
    return parseInt(this.$route.params.injuryId, 10);
  }

  get subTitle() {
    return `${this.model?.playerFirstName ?? ''} ${this.model?.playerLastName ?? ''}`.trim() ?? null;
  }

  get organisations() {
    return ReportLookupService.filterOrgs(this.lookups, StudyTypes.Community);
  }

  get studies() {
    return ReportLookupService.filterOrgStudies(this.lookups, this.model.organisationId, StudyTypes.Community);
  }

  get selectedStudy() {
    return this.studies ? this.studies.find(p => p.id === this.model.studyId) : null;
  }

  get playingPositions() {
    return ReportLookupService.getPlayingPositions(this.lookups.playingPositions!, this.studies, this.model.studyId);
  }

  get injuryTypes() {
    return this.model.injuryLocationId
      ? this.lookups.injuryLocations?.find(p => p.id === this.model.injuryLocationId)?.injuryTypes ?? []
      : [];
  }

  async created() {
    try {
      this.model = (await ApiService.communityInjuries().getCommunityInjury(this.injuryId)).data;
      this.lookups = (await ApiService.communityInjuries().getCommunityInjuryFormLookupData()).data;
    } finally {
      this.loading = false;
    }
  }
}
