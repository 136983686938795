
































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import ExistingDraftsWarningCpt from '../components/ExistingDraftsWarningCpt.vue';

@Component({
  components: {
    ExistingDraftsWarningCpt,
  },
})
export default class InjuriesGridHeaderCpt extends AppVue {
  tab = '';

  created() {
    if (this.$route.name) {
      this.tab = this.$route.name;
    }
  }

  goTo(route: string) {
    this.$router.push({ name: this.tab });
  }
}
