





































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { InjuryCollectionLookupModel, InjuryCollectionModel, InjuryGridFilter } from '@/core/webapi';
import { ApiService, ReportLookupService } from '@/core/services';
import {
  GridFactory,
  GridModel,
  GridFilter,
  GridSearchCpt,
  GridPagerCpt,
  GridSelectFilterCpt,
  StudyOrgFilterCpt,
} from '@/core/components/grid';
import { ReturnStatusTagCpt, QcStatusTagCpt } from '../components';
import { GameFormats, StudyTypes } from '@/core/constants';
import InjuriesGridHeaderCpt from './InjuriesGridHeaderCpt.vue';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
    GridSelectFilterCpt,
    StudyOrgFilterCpt,
    ReturnStatusTagCpt,
    QcStatusTagCpt,
    InjuriesGridHeaderCpt,
  },
})
export default class EliteInjuriesGridView extends AppVue {
  grid = {} as GridModel<InjuryCollectionModel>;
  lookups = {} as InjuryCollectionLookupModel;
  studyTypeId = StudyTypes.Elite;

  get showTournamentColumn() {
    if (!this.grid.collection?.items) {
      return false;
    }

    if (!this.grid.filter.studyId) {
      return !!this.isAnalyst;
    }

    const selectedStudy = ReportLookupService.getSelectedStudy(
      this.lookups,
      this.grid.filter as InjuryGridFilter,
      this.isReporter,
    );
    if (!selectedStudy) {
      return !!this.isAnalyst;
    }

    return selectedStudy.gameFormatId === GameFormats.Sevens;
  }

  async created() {
    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.injuries().filterInjuries(filter),
      routeName: 'injuries',
      defaultFilterOverrides: new GridFilter(await this.initializeFilters()),
    });
  }

  async initializeFilters() {
    this.lookups = (
      await ApiService.injuries().getInjuryCollectionLookupData(this.userContext.currentOrganisationId ?? null)
    ).data;

    let initialFilter = {
      search: '',
      organisationId: null,
      studyId: null,
      returnStatusId: null,
      qcStatusId: null,
    };

    return ReportLookupService.resolveStudyOrOrgIds(
      this.lookups,
      initialFilter,
      this.isAnalyst || this.isDevEnv,
      this.studyTypeId,
      this.isReporter,
    );
  }

  async deleteItem(id: number) {
    if (!confirm(this.$t('confirm.itemDeletion') as string)) {
      return;
    }

    await ApiService.injuries().deleteInjury(id);
    this.grid.update();
  }
}
