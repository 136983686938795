import { render, staticRenderFns } from "./CatastrophicInjuryFormView.vue?vue&type=template&id=58193976&scoped=true&"
import script from "./CatastrophicInjuryFormView.vue?vue&type=script&lang=ts&"
export * from "./CatastrophicInjuryFormView.vue?vue&type=script&lang=ts&"
import style0 from "./CatastrophicInjuryFormView.vue?vue&type=style&index=0&id=58193976&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58193976",
  null
  
)

export default component.exports